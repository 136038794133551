import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "/",
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/home",
    name: "Home",
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/about-us",
    name: "About",
    component: () => import("../views/About.vue"),
  },
  {
    path: "/pricing",
    name: "Pricing",
    component: () => import("../views/Pricing.vue"),
  },
  {
    path: "/term",
    name: "Term",
    component: () => import("../views/Term.vue"),
  },
  {
    path: "/privacy-policy",
    name: "Privacy",
    component: () => import("../views/Policy"),
  },
  {
    path: "/app-term",
    name: "AppTerm",
    props: {
      userInApp: true,
    },
    component: () => import("../views/Term.vue"),
  },
  {
    path: "/app-privacy-policy",
    name: "AppPrivacy",
    props: {
      userInApp: true,
    },
    component: () => import("../views/Policy"),
  },
  {
    path: "/help-center",
    name: "Help",
    component: () => import("../views/Help.vue"),
  },
  {
    path: "/apps-help-center",
    name: "HelpApps",
    props: {
      userInApp: true,
    },
    component: () => import("../views/Help.vue"),
  },
  {
    path: "/career",
    name: "Career",
    component: () => import("../views/Career"),
  },
  {
    path: "/manage-leads",
    name: "ManageLeads",
    component: () => import("../views/feature/Lead.vue"),
  },
  {
    path: "/message-template",
    name: "Template",
    component: () => import("../views/feature/Template.vue"),
  },
  {
    path: "/advertisement-tool",
    name: "AdvertisementTool",
    component: () => import("../views/feature/Ads.vue"),
  },
  {
    path: "/website-builder",
    name: "WebsiteBuilder",
    component: () => import("../views/feature/Web.vue"),
  },
  {
    path: "/what-is-kloser",
    name: "WhatIsKloser",
    component: () => import("../views/help/started/WhatIsKloser"),
  },
  {
    path: "/kloser-account",
    name: "KloserAccount",
    component: () => import("../views/help/started/KloserAccount"),
  },
  {
    path: "/Kloser-pin",
    name: "forgetPassword",
    component: () => import("../views/help/started/ForgetPassword"),
  },
  {
    path: "/Kloser-subscribing",
    name: "subscribing",
    component: () => import("../views/help/started/Subscribing"),
  },
  {
    path: "/Kloser-import-contact",
    name: "ContactList",
    component: () => import("../views/help/lead/ContactList"),
  },
  {
    path: "/Kloser-lead-group",
    name: "LeadGroup",
    component: () => import("../views/help/lead/LeadGroup"),
  },
  {
    path: "/Kloser-lead-detail",
    name: "LeadDetail",
    component: () => import("../views/help/lead/LeadDetail"),
  },
  {
    path: "/Kloser-edit-lead-detail",
    name: "EditLeadDetail",
    component: () => import("../views/help/lead/EditLeadDetail"),
  },
  {
    path: "/Kloser-complete-profile",
    name: "CompleteProfile",
    component: () => import("../views/help/website/CompleteProfile"),
  },
  {
    path: "/Kloser-add-lead-activity",
    name: "AddLeadActivity",
    component: () => import("../views/help/lead/AddLeadActivity"),
  },
  {
    path: "/Kloser-edit-lead-activity",
    name: "EditLeadActivity",
    component: () => import("../views/help/lead/EditLeadActivity"),
  },
  {
    path: "/Kloser-bulk-action",
    name: "BulkAction",
    component: () => import("../views/help/lead/BulkAction"),
  },
  {
    path: "/Kloser-message-template",
    name: "MessageTemplate",
    component: () => import("../views/help/lead/MessageTemplate"),
  },
  {
    path: "/Kloser-setup-payment",
    name: "SetupPayment",
    component: () => import("../views/help/payment/SetupPayment"),
  },
  {
    path: "/Kloser-edit-cards",
    name: "EditCards",
    component: () => import("../views/help/payment/EditCards"),
  },
  {
    path: "/Kloser-Subscribe",
    name: "Subscribe",
    component: () => import("../views/help/payment/Subscribe"),
  },
  {
    path: "/Kloser-cancel-Subscription",
    name: "CancelSubscription",
    component: () => import("../views/help/payment/CancelSubscription"),
  },
  {
    path: "/Kloser-refund-Subscription",
    name: "Refund",
    component: () => import("../views/help/payment/Refund"),
  },
  {
    path: "/Kloser-dashboard-introduction",
    name: "Introduction",
    component: () => import("../views/help/dashboard/Introduction"),
  },
  {
    path: "/Kloser-update-account",
    name: "UpdateAccount",
    component: () => import("../views/help/account/UpdateAccount"),
  },
  {

    path: "/Kloser-product-listing",
    name: "ProductListing",
    component: () => import("../views/help/website/ProductListing"),
  },
  {
    path: "/Kloser-collection-creation",
    name: "CollectionCreation",
    component: () => import("../views/help/website/CollectionCreation"),
  },
  {
    path: "/Kloser-share-website",
    name: "ShareWebsite",
    component: () => import("../views/help/website/ShareWebsite"),
  },
  {
    path: "/Kloser-advertisement-tool",
    name: "AdvertisementTool",
    component: () => import("../views/help/advertisement/AdvertisementTool"),
  },
  {
    path: "/quick-guide",
    name: "QuickGuide",
    component: () => import("../views/help/QuickGuide"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
