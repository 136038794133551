import { createApp } from "vue";
import VueMatchHeights from "vue-match-heights/src";
import { createGtm } from '@gtm-support/vue-gtm';
import App from "./App.vue";
import router from "./router";

import "./assets/sass/app.scss";
import "bootstrap/dist/js/bootstrap.min";
import "@popperjs/core/dist/umd/popper.min"



const app = createApp(App)
app.use(router);
app.use(VueMatchHeights);

app.use(
  createGtm({
    id: 'GTM-MQ2J4XB',
    defer: true,
    compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
    debug: true, // Whether or not display console logs debugs (optional)
    loadScript: true,
    vueRouter: router,
    trackOnNextTick: false // Whether or not call trackView in Vue.nextTick
  })
);

app.mount("#app");
